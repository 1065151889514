sortAscending = function(a,b){
  console.log("%c sort ascending","color:steelblue");
  return a.value-b.value;
};

sortDescending = function(a,b){
  // console.log("%c sort descending","color:steelblue");
  // console.log("%c typeof b.value","color:steelblue",typeof b.value);
  // console.log("%c typeof a.value","color:steelblue",typeof a.value);
  // console.log("%c b.value","color:steelblue",b.value);
  // console.log("%c a.value","color:steelblue",a.value);

  return b.value-a.value;
};

sortAscendingWithCustomIndex = function(a,b,index){
  console.log("%c sort ascending with custom index","color:#c66");
  return a[index]-b[index];
};

sortDescendingWithCustomIndex = function(a,b,index){
  console.log("%c sort descending with custom index","color:#c66");
  // console.log("%c typeof b[index]","color: #c66",typeof b[index]);
  // console.log("%c typeof a[index]","color: #c66",typeof a[index]);
  // console.log("%c b[index]","color: #c66",b[index]);
  // console.log("%c a[index]","color: #c66",a[index]);
  // console.log("%c index","color: #c66",index);
  return b[index]-a[index];
};


recipes_without_keys = [];
recipes_without_keys_length = 0;

prices_by_recipe_id = {
  last_index : 0,
  values : [],
  cache : {},
  idCache : function(id,index){
    this.cache[id] = index;
  },
  indexOf : function(id){
    return this.cache[id];
  },
  add : function(obj){
    if(obj.id == undefined){
      obj.id = obj.recipe_id;
    }
    if(this.indexOf(obj.id) == undefined){
      this.values[this.last_index] = _.extend({},obj,{index:this.last_index});
      this.idCache(obj.id,this.last_index);
      this.last_index ++ ;
    }
    else{
      this.values[this.last_index] = _.extend(this.values[this.last_index],obj,{index:this.last_index});
    }
  },
  addValues : function(values){
    for(var i = 0,len = values.length; i < len ; i ++){
      this.add(values[i]);
    }
  },
  sort : function(dir){
    if(dir == undefined)
      dir = "asc";

    switch(dir){
      case "desc" :
          this.sortDesc();
          break;
      case "asc"  :
      default     :
          this.sortAsc();
          break;
    }
  },

  sortAsc : function(){
    this.values.sort(sortAscending);
  },
  sortDesc : function(){
    this.values.sort(sortDescending);
  }

};

ops = [">=","<=",">","<"];

ENUM_OP = {
  LESS_THAN : "<",
  GREATER_THAN : ">",
  LESS_THAN_OR_EQUAL_TO : "<=",
  GREATER_THAN_OR_EQUAL_TO : ">="
};

getOps = function(exp,maxopsinexp){
  var opsinexp = [];

  if(maxopsinexp == undefined)
	  maxopsinexp = 1;

  var opsinexpcount = 0;
  ops.forEach(function(op){
    if(exp.indexOf(op) != -1 && opsinexpcount < maxopsinexp){
      opsinexp.push(op);
      opsinexpcount++;
    }
  });
  return opsinexp;
};

parseExp = function(exp){
  var splitexp = exp.split(":");
  var maxopsinexp = splitexp.length;
  var opsinexp = getOps(exp,splitexp.length);
  var valsinexp = [];
  console.log('opsinexp',opsinexp);
  for(var i = 0 ; i < maxopsinexp ; i ++){
     valsinexp.push( splitexp[i].replace( opsinexp[i],"" ) );
  }
  return {valsinexp:valsinexp,opsinexp:opsinexp,maxopsinexp:maxopsinexp};
};

evalExp = function(expObj,value){
  var condEval = [];
  for(var i = 0 ; i < expObj.maxopsinexp ; i ++){
     switch(expObj.opsinexp[i]){
       case ENUM_OP.LESS_THAN :
	 condEval[i] = (value < expObj.valsinexp[i]);
	 break;
       case ENUM_OP.GREATER_THAN :
	 condEval[i] = (value > expObj.valsinexp[i]);
	 break;
       case ENUM_OP.LESS_THAN_OR_EQUAL_TO :
	 condEval[i] = (value <= expObj.valsinexp[i]);
	 break;
       case ENUM_OP.GREATER_THAN_OR_EQUAL_TO :
	 condEval[i] = (value >= expObj.valsinexp[i]);
	 break;

       default: break;
     }
  }

  return condEval.every(function(element,index,array){
    return element === true;
  });
};

pluck = function(array, key,obj) {
  if(!Array.isArray(key)){
    return array.map(function(item) { return item[key]; });
  }
  else{
    return array.map(function(item){
      var obj = {};
      key.forEach(function(k){
	       obj[k] = item[k];
      });
      console.log
      return obj;
    });
  }
};

showPricesByRecipeId = function(arg){
  if(typeof arg == "object"){
    if(Array.isArray(arg)){
      _.map(arg,function(v){
        if(typeof v == "object")
          console.log( prices_by_recipe_id.values[prices_by_recipe_id.cache[v.recipe_id]].value);
        else if(typeof v == "string")
          console.log( prices_by_recipe_id.values[prices_by_recipe_id.cache[v]].value);
        else if(typeof v == "number")
          console.log( prices_by_recipe_id.values[v].value);
      });

    }
    else{

    }
  }
};


getRecipeIds = function(arg){
  var recipe_ids = [];
  arg.forEach(function(v){
    recipe_ids.push(v.recipe_id);
  });
  return recipe_ids;
}



recipifyArray = function(source){
  indices = {};
  source.forEach(function(el,ix,ar){
    indices[el.recipe_id] =ix;
  });

  return indices;
}

pricefyArray = function(source){
  source.forEach(function(el){
    // console.log('prices_by_recipe_id.values[prices_by_recipe_id.cache[el.recipe_id]]',prices_by_recipe_id.values[prices_by_recipe_id.cache[el.recipe_id]]);
    // console.log('prices_by_recipe_id.values[prices_by_recipe_id.cache[el.recipe_id]].value',prices_by_recipe_id.values[prices_by_recipe_id.cache[el.recipe_id]].value);
    el.value = prices_by_recipe_id.values[prices_by_recipe_id.cache[el.recipe_id]].value;
  })
  return source;
}

findInRecipes = function(source, text) {




    if(!Array.isArray(source)){
      var keys = Object.getOwnPropertyNames(source);
      text = text.split(' ');
      var output = {};
      console.log('text',text);
      keys.forEach(function(key){
        var dataSource = _.map(source[key],function(v,i){return v});

        output[key] = pluck(
                        _.filter(dataSource,function (item) {
                            for(var i=0,len = text.length; i < len ; i ++) {
                              var el = text[i];
                              // console.log('item.category.toLowerCase()',item.category.toLowerCase());
                              //
                              // console.log('item.chef_name.toLowerCase()',item.chef_name.toLowerCase());
                              //
                              // console.log('item.items_needed.toLowerCase()',item.items_needed.toLowerCase());
                              //
                              // console.log('item.recipe_description.toLowerCase()',item.recipe_description.toLowerCase());
                              //
                              // console.log('item.recipe_name.toLowerCase().',item.recipe_name.toLowerCase());
                              //
                              // console.log('item.category.toLowerCase().indexOf(el) != -1',item.category.toLowerCase(),item.category.toLowerCase().indexOf(el) != -1);
                              // console.log('item.chef_name.toLowerCase().indexOf(el) != -1',item.chef_name.toLowerCase(),item.chef_name.toLowerCase().indexOf(el) != -1);
                              // console.log('item.items_needed.toLowerCase().indexOf(el) != -1',item.items_needed.toLowerCase(),item.items_needed.toLowerCase().indexOf(el) != -1);
                              // console.log('item.recipe_description.toLowerCase().indexOf(el) != -1',item.recipe_description.toLowerCase(),item.recipe_description.toLowerCase().indexOf(el) != -1);
                              // console.log('item.recipe_name.toLowerCase().indexOf(el) != -1',item.recipe_name.toLowerCase(),item.recipe_name.toLowerCase().indexOf(el) != -1);

                          //     console.log('final',item.category.toLowerCase().indexOf(el) != -1
                          // ||  item.chef_name.toLowerCase().indexOf(el) != -1
                          // ||  item.items_needed.toLowerCase().indexOf(el) != -1
                          // ||  item.recipe_description.toLowerCase().indexOf(el) != -1
                          // ||  item.recipe_name.toLowerCase().indexOf(el) != -1,item.recipe_id,key);

                              return      item.category.toLowerCase().indexOf(el) != -1
                                    ||  item.chef_name.toLowerCase().indexOf(el) != -1
                                    ||  item.items_needed.toLowerCase().indexOf(el) != -1
                                    ||  item.recipe_description.toLowerCase().indexOf(el) != -1
                                    ||  item.recipe_name.toLowerCase().indexOf(el) != -1

                            };
                         }),['recipe_id']) ;

          //console.log('output',key,output[key]);

      });

      return output;

    }
}

MODES = {};
MODES.OBJ_MODE = 0;
MODES.ARRAY_MODE = 1;

// enum
FILTERTYPES = {};
FILTERTYPES.DISPLAY_FILTER = 1;
FILTERTYPES.SORT_FILTER = 2;
FILTERTYPES.ORDER_BY_FILTER = 3;
FILTERTYPES.SEARCH_FILTER = 4;
